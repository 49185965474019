import React from "react";
import { useSelector } from "react-redux";
import { useData } from "../../../useData";
import CardItem from "../CardItem";
import "./cardhomepage.css";
import Zoom from "react-reveal/Zoom";
const CardHomePage = () => {
  // const data = useData("get-terminology?lang=ar");
  const categories = useSelector((state) => state.webData?.categories);
  return (
    <div style={{ minHeight: "400px", padding: "0px 20px" }}>
      <div className="thecard-home mt-5">
        {Array.isArray(categories)
          ? categories?.map((item, index) => {
              return (
                <Zoom key={index}>
                  <CardItem item={item} />
                </Zoom>
              );
            })
          : null}
      </div>
      <div style={{ marginTop: "60px" }} />
    </div>
  );
};

export default CardHomePage;
