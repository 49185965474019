import React from "react";
import { Link } from "react-router-dom";
import { imgUrl } from "../../variables";

function CardItem({ item }) {
  return (
    <div className="flex-center">
      <div className="card-item mb-3">
        <div style={{ height: "120px" }} className="flex-center">
          <div className="text-content">
            <h5>{item?.ar_term}</h5>
          </div>
        </div>
        <div style={{ height: "200px" }} className="card-img">
          <img
            style={{
              height: "200px",
              width: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
            src={`${imgUrl}/${item?.path}/${item?.image}`}
            alt="imgcard"
          />
        </div>

        <Link to={`/expressions/${item?.slug}`}>
          <div className="text-more mb-1">
            {" "}
            للمزيد
            <span style={{ fontSize: "10px" }}>»</span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default CardItem;
