import React from "react";
import { useSelector } from "react-redux";

const Sources = ({ title }) => {
  const sources = useSelector((state) => state.webData.about_us?.sources);
  console.log('sources', sources);

  // Function to add target="_blank" to anchor tags
  const addTargetBlankToLinks = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const links = doc.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer'); // for security
    });
    return doc.body.innerHTML;
  };

  return (
    <div>
      <div className="flex-center">
        <h1 className="text-center mt-4 mb-5 custom-h1">المصادر</h1>
      </div>
      <div style={{ direction: "rtl" }} className="container mt-4 mb-5">
        {sources?.map((item, index) => {
          // Add target="_blank" to links in the source content
          const modifiedContent = addTargetBlankToLinks(item?.source_content);
          return <div className="ECES" key={index} dangerouslySetInnerHTML={{ __html: modifiedContent }} />;
        })}
      </div>
    </div>
  );
};

export default Sources;

/*
<div className={style.itemList}>
                          (OECD) منظمة التعاون والتنمية الاقتصادية
                        </div>
                        <div className={style.itemList}>
                          (IMF) صندوق النقد الدولي
                        </div>
                        <div className={style.itemList}>(FinDev) بوابة </div>
                        <div className={style.itemList}>
                          (ILO) موقع منظمة العمل الدولية
                        </div>
                        <div className={style.itemList}>(Cognizant) موقع </div>
                        <div className={style.itemList}>
                          منظمة الملكية الفكرية العالمية (الويبو)
                        </div>
              */
